import { centsToDollars, formatMoney } from '@/utils/conversions';

const getLineItems = reservationEstimate =>
  reservationEstimate?.invoice?.['invoice-payments']?.[0]?.[
    'charge-line-items'
  ] || [];

export const lineItemAmount = (reservationEstimate, name) => {
  const lineItems = getLineItems(reservationEstimate);

  return centsToDollars(lineItems.find(li => li.name === name)?.amount || 0);
};

export const totalAmount = reservationEstimate => {
  const lineItems = getLineItems(reservationEstimate);

  let amount = lineItems.reduce((sum, cli) => sum + cli.amount, 0);

  if (amount < 0) {
    amount = 0;
  }

  return formatMoney(centsToDollars(amount));
};

export const creditAmount = reservationEstimate => {
  const lineItems = getLineItems(reservationEstimate);

  return centsToDollars(
    lineItems
      .filter(cli => cli.name !== 'promo' && cli.amount < 0)
      .reduce((sum, cli) => sum + cli.amount, 0)
  );
};

export const getTaxLineItems = invoicePayments =>
  invoicePayments
    ?.filter(invoices => invoices['charge-line-items'])
    .flatMap(items => items['charge-line-items'])
    .filter(item => item.name.includes('tax'))
    .map(item => ({
      name: item.name,
      description: item.description,
      amount: item.amount,
    }));

export const getTotalTaxAmount = invoicePayments =>
  invoicePayments?.reduce((sum, { 'total-tax': totalTax }) => {
    const newSum = sum + totalTax;
    return newSum;
  }, 0);
