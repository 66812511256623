import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ampli } from '@/amplitude';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  PillButton,
} from '@/base-components';

import { PromoCodeLineItem } from './Reservation/LineItemStyles';

const name = 'promo-code';

const PromoCode = ({ error, onClick, amount }) => {
  const { t } = useTranslation(['common', 'reservations']);
  const { control, setValue, watch } = useFormContext();
  const { pathname } = useRouter();
  const isInvalid = error?.length > 1;
  const visible = watch(name);
  const showPromoLineItem = visible !== '' && amount !== 0;

  const handleClick = () => {
    ampli.promoCodeAttempt();
    onClick();
  };

  const getClassName = () => {
    if (pathname.includes('/book')) {
      return 'reservation-review-add-promo';
    }
    return '';
  };
  const handleRemove = () => {
    setValue(name, '');
    onClick();
  };

  return (
    <Box height="fit-content">
      {showPromoLineItem && (
        <PromoCodeLineItem amount={amount} handleRemove={handleRemove} />
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl name={name} isInvalid={isInvalid}>
            <InputGroup>
              <Input
                type="text"
                variant="outline"
                isInvalid={isInvalid}
                name={name}
                placeholder={t('reservations:promoCode')}
                sx={{ textTransform: 'uppercase' }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onClick();
                  }
                }}
                {...field}
              />
              <InputRightElement w="auto">
                <PillButton
                  data-test-id="button-apply-promo-code"
                  onClick={handleClick}
                  m={1}
                  className={getClassName()}
                >
                  {t('button.apply')}
                </PillButton>
              </InputRightElement>
            </InputGroup>
            {isInvalid && <FormErrorMessage mt={2}>{error}</FormErrorMessage>}
          </FormControl>
        )}
      />
    </Box>
  );
};

PromoCode.propTypes = {
  error: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
};

export default PromoCode;
