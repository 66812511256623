import { ArrowRightIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import React from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  FlexCol,
} from '@/base-components';
import Link from '@/base-components/Link';

type ActionProps = {
  requiredActions: { action: string; link: string }[];
};
export const RequiredActionsCard = ({ requiredActions }: ActionProps) => {
  const { t } = useTranslation(['common', 'search']);

  return (
    <Alert
      status="error"
      padding={4}
      sx={{
        bg: 'red.50',
        border: '1px solid',
        borderColor: 'error',
        alignItems: 'start',
      }}
    >
      <AlertIcon />
      <FlexCol>
        <AlertTitle>
          {t('search:insuranceAlert.title', { count: requiredActions.length })}
        </AlertTitle>
        <AlertDescription>
          {t('search:insuranceAlert.message')}
        </AlertDescription>
        {requiredActions.map(action => (
          <Link
            key={action.action}
            mt={2}
            color="black"
            textDecoration="underline"
            fontWeight="bold"
            href={action.link}
          >
            {action.action}
            <ArrowRightIcon />
          </Link>
        ))}
      </FlexCol>
    </Alert>
  );
};
