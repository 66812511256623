import styled from '@emotion/styled';
import { Box, chakra, Flex } from '@fluidtruck/core';
import { Divider } from '@material-ui/core';
import { useTheme } from 'emotion-theming';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import {
  FlexCol,
  FlexRow,
  GrayText,
  Header,
  HeaderLink,
  InfoTooltip,
  Text,
} from '@/base-components';
import { centsToDollars, formatMoney } from '@/utils/conversions';
import { generateMQ } from '@/utils/helpers';

const mq = generateMQ('max');

export const DividingBorder = styled(props => <Divider {...props} />)(
  props => ({
    width: 'calc(100% + 60px)',
    marginLeft: -30,
    marginTop: 32,
    marginBottom: 32,
    color: props?.theme?.colors?.gray[400],
    [mq[0]]: {
      marginLeft: '-22px',
      width: 'calc(100% + 44px)',
    },
  })
);

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  margin: '12px 0',
});

const AdditionalChargeContainer = styled.div({
  marginTop: 24,
});

const TitleAndInfo = styled.div({
  display: 'flex',
  justify: 'flex-start',
  flexDirection: 'row',
  alignItems: 'center',
  [mq[0]]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const TitleAndAction = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
  },
  label: 'TitleAndAction',
});

export const Spacer = styled.div(props => ({
  marginLeft: 8,
  marginRight: 8,
  color: props?.theme?.colors?.gray[400],
  [mq[0]]: {
    display: 'none',
  },
}));

const StyledText = styled.span(props => ({
  fontFamily: props?.theme?.font,
  fontSize: 14,
  fontWeight: 400,
}));

export const AdditionalCharge = ({ title, subtitle, description }) => (
  <AdditionalChargeContainer>
    <TitleAndInfo>
      <Text fontSize={18}>{title}</Text>
      <Spacer>|</Spacer>
      <Text fontSize={18} textAlign="right">
        {subtitle}
      </Text>
    </TitleAndInfo>
    <GrayText color="darkGray">{description}</GrayText>
  </AdditionalChargeContainer>
);

AdditionalCharge.propTypes = {
  description: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export const Estimate = ({ amount, unlimitedMiles }) => {
  const { t } = useTranslation('reservations');
  return (
    <FlexCol my="12px">
      <FlexRow justifyContent="space-between" alignItems="center" mb="1">
        <Header size={16}>{t('reservation')}</Header>
        <Box>
          {unlimitedMiles ? (
            <Header size={16}>{formatMoney(0)}</Header>
          ) : (
            <Header size={16}>{formatMoney(amount)}</Header>
          )}
        </Box>
      </FlexRow>
    </FlexCol>
  );
};

export const PremiumMaintenance = ({ amount }) => {
  const { t } = useTranslation('reservations');

  if (amount <= 0) return <></>;

  return (
    <FlexCol my="12px">
      <FlexRow justifyContent="space-between" alignItems="center" mb="1">
        <Header size={16}>{t('premiumMaintenance')}</Header>
        <Box>
          <Header size={16}>{formatMoney(amount)}</Header>
        </Box>
      </FlexRow>
    </FlexCol>
  );
};

Estimate.propTypes = {
  milesIncluded: PropTypes.number,
  costPerMile: PropTypes.number,
  amount: PropTypes.number.isRequired,
  unlimitedMiles: PropTypes.bool,
};

Estimate.defaultProps = {
  milesIncluded: 0,
  unlimitedMiles: false,
};

export const ServiceFee = ({ amount }) => {
  const { t } = useTranslation('reservations');
  return (
    <Box mb="22px">
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow alignItems="center">
          <Header size={16}>{t('reservationFeeHeader')}</Header>
          <InfoTooltip
            label={<StyledText>{t('reservationFeeTooltip')}</StyledText>}
            aria-label="reservation Fee Tooltip"
            placement="top"
            data-test-id="res-fee-tooltip"
          />
        </FlexRow>
        <Header size={16}>{formatMoney(amount)}</Header>
      </FlexRow>
    </Box>
  );
};

ServiceFee.propTypes = {
  amount: PropTypes.number.isRequired,
};

export const DeliveryFee = ({ amount }) => {
  const { t } = useTranslation('reservations');
  return (
    <Box mb="22px">
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow alignItems="center">
          <Header size={16}>{t('reservationDelivery')}</Header>
        </FlexRow>
        <Header size={16}>{formatMoney(amount)}</Header>
      </FlexRow>
    </Box>
  );
};

DeliveryFee.propTypes = {
  amount: PropTypes.number.isRequired,
};

export const RetrievalFee = ({ amount }) => {
  const { t } = useTranslation('reservations');
  return (
    <Box mb="22px">
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow alignItems="center">
          <Header size={16}>{t('reservationRetrieval')}</Header>
        </FlexRow>
        <Header size={16}>{formatMoney(amount)}</Header>
      </FlexRow>
    </Box>
  );
};

RetrievalFee.propTypes = {
  amount: PropTypes.number.isRequired,
};

export const Deposit = ({ amount }) => {
  const { t } = useTranslation('reservations');

  if (amount <= 0) {
    return null;
  }

  return (
    <Box mb="22px">
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow alignItems="center">
          <Header size={16}>{t('reservationDepositHeader')}</Header>
          <InfoTooltip
            label={<StyledText>{t('reservationDepositTooltip')}</StyledText>}
            aria-label="reservation Deposit Tooltip"
            placement="top"
            data-test-id="res-deposit-tooltip"
          />
        </FlexRow>
        <Header size={16}>{formatMoney(amount)}</Header>
      </FlexRow>
    </Box>
  );
};

Deposit.propTypes = {
  amount: PropTypes.number.isRequired,
};

export const UserCredit = ({ amount }) => {
  const { t } = useTranslation();
  if (amount >= 0) {
    return null;
  }

  return (
    <Container style={{ marginBottom: 22 }}>
      <div>
        <TitleAndInfo>
          <Header size={16}>{t('userCreditHeader')}</Header>
        </TitleAndInfo>
      </div>
      <div>
        <Header size={16}>{formatMoney(amount)}</Header>
      </div>
    </Container>
  );
};

UserCredit.propTypes = {
  amount: PropTypes.number.isRequired,
};

export const PromoCodeLineItem = ({ amount, handleRemove }) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'reservations']);

  return (
    <Container>
      <div>
        <TitleAndAction>
          <Header size={16} style={{ lineHeight: '22px' }}>
            {t('reservations:promoCode')}
          </Header>
          <HeaderLink
            size={14}
            style={{ marginLeft: 6, cursor: 'pointer' }}
            onClick={handleRemove}
          >
            {t('button.remove')}
          </HeaderLink>
        </TitleAndAction>
      </div>
      <div>
        <Header size={16} style={{ color: theme?.colors?.green[500] }}>
          {formatMoney(amount)}
        </Header>
      </div>
    </Container>
  );
};

PromoCodeLineItem.propTypes = {
  amount: PropTypes.number,
  handleRemove: PropTypes.func.isRequired,
};

PromoCodeLineItem.defaultProps = {
  amount: 0,
};

export const SalesTaxLineItem = ({ amount, lineItems }) => {
  const { t } = useTranslation('reservations');

  return (
    <Box style={{ marginBottom: 22 }} data-test-id="line-item-salestax">
      <FlexRow justifyContent="space-between">
        <FlexRow>
          <Header size={16}>
            {t('charges.salesTax')}
            {lineItems?.length && (
              <InfoTooltip
                width="14rem"
                label={
                  <StyledText>
                    {lineItems?.map(
                      (item, i) =>
                        `${item.description}${
                          i < lineItems.length - 1 ? '; ' : ''
                        }`
                    )}
                  </StyledText>
                }
                aria-label="reservation Sales Tax Tooltip"
                placement="top"
                dataTestId="sales-tax-tooltip"
              />
            )}
          </Header>
        </FlexRow>
        <Header size={16}>{formatMoney(centsToDollars(amount))}</Header>
      </FlexRow>
    </Box>
  );
};

SalesTaxLineItem.propTypes = {
  amount: PropTypes.number.isRequired,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string,
      amount: PropTypes.number,
    })
  ),
};
